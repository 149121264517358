import $ from 'jquery';

const BRUTTO_NETTO_SELECTOR = '.js-brutto-netto';
const BRUTTO_CLASS = 'show-brutto-price';
const NETTO_CLASS = 'show-netto-price';
const BRUTTO = 'brutto';
const NETTO = 'netto';

let $html = $('html');

function changeRadioButtonsCheckedByValue(value) {
	$(BRUTTO_NETTO_SELECTOR).each(function () {
		if (this.value == value) {
			this.checked = true;
		}
	});
}

$(BRUTTO_NETTO_SELECTOR).change(function () {
	if (this.value == NETTO) {
		$html.addClass(NETTO_CLASS);
		$html.removeClass(BRUTTO_CLASS);
		changeRadioButtonsCheckedByValue(NETTO);
	} else {
		$html.addClass(BRUTTO_CLASS);
		$html.removeClass(NETTO_CLASS);
		changeRadioButtonsCheckedByValue(BRUTTO);
	}
});

$(document).ready(function () {
	changeRadioButtonsCheckedByValue($html.hasClass(NETTO_CLASS) ? NETTO : BRUTTO);
});
