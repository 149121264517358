/* eslint-disable no-new */

import $ from 'jquery';
import { Carousel } from './Carousel';
import { SWHREF } from './SWHREF';
import { SVGPosition } from './SVGPosition';

const SELECTORS = {
	INLINE_PROMO_CONTAINER: '.module-container.m13p-inline-promo',
	INLINE_PROMO_WRAP: '.m13p-inline-promo-wrap',
	SELECT_SELECTOR: '.m13p-inline-promo-pager-select .progressDeviceSelect',
	PAGER_SELECTOR: '.m13p-inline-promo-pager__item',
	PAGER_ACTIVE_CLASS: 'm13p-inline-promo-pager__item--active'
};
const SVG_POSITION_OPTIONS = {
	initDelay: 1000
};

const ANIMATION_TIMES = {
	SLIDE_DURATION: 500,
	FADE_IN: 600
};
const INLINE_PROMO_SWIPER_PARAMS = {
	wrapperClass: 'm13p-inline-promo__content',
	loop: true,
	slideClass: 'm13p-inline-promo-slide',
	prevButton: '.m13p-inline-promo-controls-prev',
	nextButton: '.m13p-inline-promo-controls-next',
	duration: ANIMATION_TIMES.SLIDE_DURATION,
	onTransitionStart: function (swiper) {
		$(swiper.container)
			.find('.m13p-inline-promo-slide')
			.each(function () {
				if ($(this).index() !== swiper.activeIndex) {
					$(this).find('.m13p-inline-promo-slide__text-wrap').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.m13p-inline-promo-slide__image-wrap').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.m13p-inline-promo-slide__background').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
				}
			});
	},
	onSlideChangeStart: function (swiper) {
		$(swiper.container)
			.find('.m13p-inline-promo-slide')
			.each(function () {
				if ($(this).index() === swiper.activeIndex) {
					$(this).find('.m13p-inline-promo-slide__text-wrap').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.m13p-inline-promo-slide__image-wrap').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.m13p-inline-promo-slide__background').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
				}
			});
	}
};

export class PersonalizedInlinePromoSitebuild {
	constructor(inlinePromoContainer) {
		this.inlinePromoContainer = $(inlinePromoContainer);
	}

	init() {
		new Carousel(this.inlinePromoContainer, INLINE_PROMO_SWIPER_PARAMS, SELECTORS);
		new SVGPosition(this.inlinePromoContainer, SVG_POSITION_OPTIONS);

		$(this.inlinePromoContainer)
			.find('.m13p-inline-promo-slide')
			.each(function () {
				SWHREF.initLinkEventTrigger(this);
			});
	}
}
