import $ from 'jquery';
import PersonalizedModulesApi from '../api/PersonalizedModulesApi';
import { PersonalizedInlinePromoSitebuild } from './PersonalizedInlinePromoSitebuild';
import EventLog from './EventLog';

/**
 * perszonalizált M13 modul
 */
export class PersonalizedInlinePromo {
	constructor(container) {
		this.service = new PersonalizedModulesApi();
		this.personalizedInlinePromoSitebuild = new PersonalizedInlinePromoSitebuild(container);
		this.LOADER_SELECTOR = '.m13p-inline-promo-loader';
		this.SLIDE_DATA_ELEMENT_SELECTOR = 'data-slide-id';
		this.SLIDE_TEXT_ELEMENT_SELECTOR = 'js-slide-text-id-';
		this.DEFAULT_SLIDE_ELEMENT_SELECTOR_ID = 'js-default-slide';
		this.NAV_ELEMENTS_SELECTOR = 'nav > a';
		this.SELECT_OPTIONS_SELECTOR = 'select option';
		this.SLIDER_ELEM_ACTIVE_CLASS = 'm13p-inline-promo-pager__item--active';
		this.PROGRESS_DEVICE_SELECT_CLASS = '.progressDeviceSelect';
		this.PERSONALIZED_OFFER_URL_SELECTOR = 'data-m13p-personalized-url';
		this.PREV_BUTTON = '.m13p-inline-promo-controls-prev';
		this.NEXT_BUTTON = '.m13p-inline-promo-controls-next';
		this.PROMO_BUTTON = '.m13p-inline-promo-button';
		this.PROMO_LINK = '.m13p-inline-promo-link';
		this.MODULE_ID = 'data-m13p-module-id';
		this.SLIDE_SELECTOR = '.m13p-inline-promo-slide';
		this.sliderElemIds = null;
		this.container = container;
		this.$slider = $(this.container);
		this.selectedIds = [];

		this.getSlideIdsForShow();
	}

	getSlideIdsForShow() {
		let personalizedOfferUrl = this.getAttributeValue(this.PERSONALIZED_OFFER_URL_SELECTOR);
		let cetModuleId = this.getAttributeValue(this.MODULE_ID);

		this.hideSliders();
		this.showLoader();
		this.service.postModuleInfo({ personalizedOfferUrl, cetModuleId }).then(
			(response) => {
				this.initPersonalizedCards(response);
			},
			() => {
				this.initCards(true);
			}
		);
	}

	initPersonalizedCards(data) {
		this.sliderElemIds = data._id;

		if (this.sliderElemIds && this.sliderElemIds.length && this.checkCorrespondRestIdWithSlidersId()) {
			this.$slider.attr('data-personalized-id', this.selectedIds);
			this.initCards(false);
		} else {
			this.initCards(true);
		}

		this.initEventLog();
	}

	/**
	 * Ha restről jött adat akkor az alapján jeleníti meg a kártyákan,
	 * különben a blondyba beszerkesztett default kártyákat mutatja.
	 * @param setDefaultState
	 */
	initCards(setDefaultState) {
		this.replaceSlider(setDefaultState);
		this.hideLoader();
		this.personalizedInlinePromoSitebuild.init();
	}

	replaceSlider(setDefaultState) {
		// sliderElemIds
		let removeSlideElemIndexes = this.removeElements(setDefaultState);
		this.removeNavElement(removeSlideElemIndexes);
		this.removeMobileViewSelectElement(removeSlideElemIndexes);
		if (!setDefaultState) {
			this.sortSliders();
		}
		this.showSliders();
	}

	sortSliders() {
		for (let i = 0; i < this.sliderElemIds.length; i++) {
			let jsSliderSelector = `[${this.SLIDE_DATA_ELEMENT_SELECTOR}="${this.sliderElemIds[i]}"]`;
			let jsSliderTextSelectorId = this.SLIDE_TEXT_ELEMENT_SELECTOR + this.sliderElemIds[i];
			let slideElement = this.$slider.find(jsSliderSelector);

			if (!slideElement.length) {
				continue;
			}

			let $slideElement = $(slideElement[0]);
			let $sliderTextElement = $(this.$slider.find('nav > .' + jsSliderTextSelectorId)[0]);
			let $sliderMobileViewTextElement = $(this.$slider.find(this.PROGRESS_DEVICE_SELECT_CLASS + ' > .' + jsSliderTextSelectorId)[0]);
			let slideParentElement = slideElement.parent();
			let sliderTextParentElement = $sliderTextElement.parent();
			let sliderMobileViewTextParentElement = $sliderMobileViewTextElement.parent();

			$slideElement.detach().appendTo(slideParentElement);
			$sliderTextElement.detach().appendTo(sliderTextParentElement);
			$sliderMobileViewTextElement.detach().appendTo(sliderMobileViewTextParentElement);
		}

		this.reindexSelectOptions();
	}

	checkCorrespondRestIdWithSlidersId() {
		let numberOfCorrespondIds = 0;

		for (let i = 0; i < this.sliderElemIds.length; i++) {
			let jsSliderSelector = `[${this.SLIDE_DATA_ELEMENT_SELECTOR}="${this.sliderElemIds[i]}"]`;
			let slideElement = this.$slider.find(jsSliderSelector);

			if (slideElement.length) {
				numberOfCorrespondIds++;
				this.selectedIds.push(this.sliderElemIds[i]);
			}
		}

		return numberOfCorrespondIds > 0;
	}

	/**
	 * Ha hibára fut a rest vagy nem küldenek id-t, akkor a default classal megjelölt slideokat kell megjeleníteni,
	 * különben a resten jött id-khoz tartozó slideok látszódnak és a többit törli.
	 * @param $slider: slider content jquery által kiszelektált példánya
	 * @param setDefaultState: true vagy false lehet
	 * @returns {Array}: a törlendő slide-ok indexe
	 */
	removeElements(setDefaultState) {
		let slideElements = this.$slider.find(`[${this.SLIDE_DATA_ELEMENT_SELECTOR}]`);
		let removeSlideElemIndexes = [];

		for (let i = 0; i < slideElements.length; i++) {
			let $selectedElem = $(slideElements[i])[0];
			let classListValue = $selectedElem.classList.value;
			let sliderIsUsed = setDefaultState ? this.isDefaultElements(classListValue) : this.isPersonalizedSlider($selectedElem);

			if (!sliderIsUsed) {
				removeSlideElemIndexes.push(i);
				$($selectedElem).remove();
			}
		}
		return removeSlideElemIndexes;
	}

	isPersonalizedSlider($selectedElem) {
		let sliderId = this.getAttributeValue(this.SLIDE_DATA_ELEMENT_SELECTOR, $selectedElem);

		if (this.sliderElemIds.indexOf(sliderId) == -1) {
			return false;
		}

		return true;
	}

	isDefaultElements(classListValue) {
		if (classListValue.indexOf(this.DEFAULT_SLIDE_ELEMENT_SELECTOR_ID) == -1) {
			return false;
		}

		return true;
	}

	removeNavElement(removeSlideElemIndexes) {
		let navElement = this.$slider.find(this.NAV_ELEMENTS_SELECTOR);
		for (let i = 0; i < removeSlideElemIndexes.length; i++) {
			$(navElement[removeSlideElemIndexes[i]]).remove();
		}
		navElement = this.$slider.find(this.NAV_ELEMENTS_SELECTOR);
		$(navElement[0]).addClass(this.SLIDER_ELEM_ACTIVE_CLASS);
	}

	removeMobileViewSelectElement(removeSlideElemIndexes) {
		let selectOptionsElement = this.$slider.find(this.SELECT_OPTIONS_SELECTOR);
		for (let i = 0; i < selectOptionsElement.length; i++) {
			if (removeSlideElemIndexes.indexOf(i) >= 0) {
				$(selectOptionsElement[i]).remove();
			}
		}
		this.reindexSelectOptions();
	}

	reindexSelectOptions() {
		let selectOptionsElement = this.$slider.find(this.SELECT_OPTIONS_SELECTOR);
		for (let i = 0; i < selectOptionsElement.length; i++) {
			selectOptionsElement[i].value = i;
		}
	}

	initEventLog() {
		let self = this;
		this.$slider.find(`[${this.SLIDE_DATA_ELEMENT_SELECTOR}]`).each(function () {
			let slideElement = this;

			if ($(slideElement).find(self.PROMO_BUTTON).length > 0) {
				EventLog.promoButton(slideElement, self.PROMO_BUTTON);
			}

			if ($(slideElement).find(self.PROMO_LINK).length > 0) {
				EventLog.promoButton(slideElement, self.PROMO_LINK);
			}
		});
	}

	getAttributeValue(attribute, container = this.container) {
		return $(container).val($(attribute)).attr(attribute);
	}

	showLoader() {
		$(this.LOADER_SELECTOR).show();
	}

	hideLoader() {
		$(this.LOADER_SELECTOR).hide();
	}

	showSliders() {
		$(this.container).show();
	}

	hideSliders() {
		$(this.container).hide();
	}
}
