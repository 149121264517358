'use-strict';
import $ from 'jquery';

import { PersonalizedInlinePromo } from './Class/PersonalizedInlinePromo';

const SLIDER_CONTAINER_SELECTOR_ID = '[class*= js-slider-container-]';

$(document).ready(function () {
	$(SLIDER_CONTAINER_SELECTOR_ID).each(function () {
		new PersonalizedInlinePromo(this); // eslint-disable-line no-new
	});
});
