/* eslint-disable max-lines */
import $ from 'jquery';
import angular from 'angular';
import '../lib/jquery-scrollbar/jquery.scrollbar.min.js';
const removeAccents = (text) => {
	const accentReplace = {
		a: /[áä]/gm,
		A: /[ÁÄ]/gm,
		e: /[éê]/gm,
		E: /[ÉÊ]/gm,
		i: /[íî]/gm,
		I: /[ÍÎ]/gm,
		o: /[óöőõø]/gm,
		O: /[ÓÖŐÕØ]/gm,
		u: /[úüűû]/gm,
		U: /[ÚÜŰÛ]/gm
	};
	for (var acc in accentReplace) {
		text = text.replace(accentReplace[acc], acc);
	}
	return text;
};
var app = angular.module('roamingApp', []);
app.controller('roamingAppController', [
	'$scope',
	'$timeout',
	'$window',
	function ($scope, $timeout, $window) {
		$scope.roamingAppData = [];
		$scope.isSelectedCountry = false;
		$scope.selectedCountryName = '';
		$scope.selectedCountry;
		$scope.defaultHeadlineText = angular.element('.roaming-app-hero-title__text-container-headline').text();
		$scope.countries;
		$scope.operatorsOfSelectedCountry;
		$scope.pleaseSelectCountry = 'Kérjük, válassz országot!';
		$scope.selectedOperatorName;
		$scope.isSelectedCountryInList = false;

		$scope.showCountryDetails = function () {
			var isValidCountryName = $scope.countriesWithoutAccent.includes(removeAccents($scope.selectedCountryName));
			if (isValidCountryName) {
				var queryParams = new URLSearchParams($window.location.search);
				queryParams.set('country', removeAccents($scope.selectedCountryName));
				history.replaceState(null, null, '?' + queryParams.toString());
				$scope.isSelectedCountry = true;
				angular.element('.roaming-app-hero-title__text-container-headline').text($scope.selectedCountryName);
				$scope.getCountryDetails($scope.selectedCountryName);
				$scope.getOperatorsOfCountry($scope.selectedCountryName);
				$scope.selectedOperatorName = $scope.operatorsOfSelectedCountry[0].operator;
			}
		};

		$scope.showCountryList = function () {
			$scope.isSelectedCountry = false;
			angular.element('.roaming-app-hero-title__text-container-headline').text($scope.defaultHeadlineText);
			$scope.selectedCountryName = $scope.pleaseSelectCountry;
			$scope.selectCountryInList(false);
			$scope.resetSelectedCountry();
		};

		$scope.resetSelectedCountry = function () {
			if ($window.location.hash || $window.location.search) {
				history.replaceState(null, null, $window.location.pathname);
			}
		};

		$scope.getCountryDetails = function (country) {
			$scope.selectedCountry = $scope.roamingAppData.find((e) => e.country === country);
			$timeout(function () {
				$scope.styledSelect();
			}, 0);
			return $scope.selectedCountry;
		};

		$scope.getSelectedCountryDetails = function (selectedCountry) {
			$scope.selectedCountry = selectedCountry;
			$scope.selectedOperatorName = selectedCountry.operator;
		};

		$scope.getOperatorsOfCountry = function (country) {
			$scope.operatorsOfSelectedCountry = $scope.roamingAppData.filter((element) => element.country === country);
			$scope.operatorsOfSelectedCountry = $scope.operatorsOfSelectedCountry.map((item) => {
				item.operator = item.operator.replace('&amp;', '&');
				return item;
			});
			return $scope.operatorsOfSelectedCountry;
		};

		$scope.initDynamicScrollbar = function () {
			angular.element('.scrollbar-dynamic').scrollbar();
		};

		$scope.selectCountryInList = function (countryName) {
			if (countryName) {
				$scope.isSelectedCountryInList = 'selected';
				$scope.selectedCountryName = countryName;
			} else {
				$scope.isSelectedCountryInList = '';
				$scope.selectedCountryName = $scope.pleaseSelectCountry;
			}
		};
		$scope.setCountries = function () {
			var queryParams = new URLSearchParams($window.location.search);
			var countryParam = queryParams.get('country');
			$scope.countries = Array.from(new Set($scope.roamingAppData.map((item) => item.country)));
			$scope.countriesWithoutAccent = $scope.countries.map((country) => removeAccents(country));
			$scope.$apply();
			if (countryParam) {
				const indexOfCountry = $scope.countriesWithoutAccent.findIndex((country) => country.toLowerCase() == countryParam.toLowerCase());
				$scope.selectedCountryName = $scope.countries[indexOfCountry];
				$scope.showCountryDetails();
			}
		};
		$scope.init = function () {
			if (Array.isArray(window.roamingAppJson)) {
				$scope.roamingAppData = $window.roamingAppJson;
				$scope.setCountries();
			} else {
				fetch(window.roamingAppJson)
					.then((response) => response.json())
					.then((data) => {
						$scope.roamingAppData = data;
						$scope.setCountries();
					});
			}
			$scope.initDynamicScrollbar();
			$scope.selectedCountryName = $scope.pleaseSelectCountry;
			$timeout(function () {
				$scope.styledSelect();
			}, 0);
		};

		$scope.styledSelect = function () {
			$('select').each(function () {
				var $this = $(this);
				var $styledSelect = $this.next('div.styledSelect');
				var $list = $('.roaming-app ul:visible');
				var $listItems = $('.roaming-app li:visible');

				$styledSelect.click(function (e) {
					e.stopPropagation();
					$('div.styledSelect.active').each(function () {
						$(this).removeClass('active').next('div.scrollbar-dynamic').find('ul.options').hide();
					});
					$('.roaming-app .select:visible .scroll-wrapper').show();
				});

				$listItems.click(function (e) {
					e.stopPropagation();
					$styledSelect.text($(this).text()).removeClass('active');
					$this.val($(this).attr('rel'));
					$list.hide();
					$('.roaming-app .select:visible .scroll-wrapper').hide();
				});

				$(document).click(function (event) {
					var clickedClassName = event.target.className;
					var isScrollbarClicked = clickedClassName.includes('scroll-wrapper') || clickedClassName.includes('scroll-bar') || clickedClassName.includes('scroll-element_track');
					if (!isScrollbarClicked) {
						$styledSelect.removeClass('active');
						$list.hide();
						$('.roaming-app .select:visible .scroll-wrapper').hide();
					}
				});
			});
		};

		$timeout(function () {
			$scope.init();
		}, 0);
	}
]);
